import React, { useState, useEffect, useCallback } from "react";
import { sensorRegistersType, sensorService } from "../../../../../_services/sensors.service";
import { useApp } from "../../../../../context/app-context";

import "./MultipleStatistics.sass";
import "./LiquidBarchart.sass";
import "./Statistics.sass";
import {
  getDayOfYearFormatted,
  getDateTimeFormatted,
  getHoursMinuteTimeFormatted
} from "../../../../../_helpers/DateFormatHelper";
import { outputRegistersType, OutputService } from "../../../../../_services/outputcontroller.service";
import { TerminalDeviceSensorLineGraph } from "../../SensorsView/TerminalDeviceSensorLineGraph";
import { projectsConstants } from "../../../../../_constants/projects.constants";
import * as XLSX from "xlsx";
import { periods, PeriodSelector } from "../../../date/PeriodSelector";
import { DangerComponent } from "../../../../DangerComponent/DangerComponent";
import { BreakLine } from "../../../break/BreakLine";
import { Spinner } from "../../../Airframe/Spinner/Spinner";

const weekDays = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];
const months = ['E', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'];
const getDaysInMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate(); // Obtiene el último día del mes
};

const isTheSameSensorId = (source, target) => {
  return source?.sensorIndex === target?.sensorIndex 
  && source?.sensorId?.physicalCommunicationType === target?.sensorId?.physicalCommunicationType
  && source?.sensorId?.id === target?.sensorId?.id
}

export const TerminalDeviceSensorStats = (props) => {
  const { sensor: initialSensor, output: initialOutput } = props;

  const [state, setState] = useState({
    day: new Date(),
    period: periods.DAY
  })
  const [data, setData] = useState(undefined);
  const [showAccumulated, setShowAccumulated] = useState(false);
  const [sensor, setSensor] = useState(initialSensor)
  const [output, setOutput] = useState(initialOutput)

  const { selectedTerminal } = useApp();

  useEffect(() => {
    if(!(isTheSameSensorId(initialSensor, sensor))){
      setSensor(initialSensor)
    }
  }, [initialSensor])

  useEffect(() => {
    if(initialOutput?.output !== output?.output){
      setOutput(initialOutput)
    }
  }, [initialOutput])

  const isValidForAccumulated = useCallback(() => {
    return (output ||
      sensor?.sensorId?.physicalCommunicationType ===
        projectsConstants.global.sensors.phys.cuds ||
      sensor?.sensorId?.physicalCommunicationType ===
        projectsConstants.global.sensors.phys.digital)
  }, [output, sensor?.sensorId?.physicalCommunicationType])

  const getRequestParams = useCallback(() => {
    const from = new Date(state.day)
    const to = new Date(state.day)
    let type = sensorRegistersType.MAIN
    to.setDate(from.getDate() + 1)

    if(output){
      if(state.period === periods.WEEK){
        type =  outputRegistersType.DAY_ACCUMULATED
      }
      else if(state.period === periods.MONTH){
        type = outputRegistersType.DAY_ACCUMULATED
      }
      else if(state.period === periods.YEAR){
        type = outputRegistersType.MONTH_ACCUMULATED
      }
      else if(state.period === periods.TOTAL){
        type = outputRegistersType.YEAR_ACCUMULATED
      }
      else{
        type = outputRegistersType.MAIN
      }
    }
    else {
      if(state.period === periods.WEEK){
        type =  sensorRegistersType.DAY_ACCUMULATED
      }
      else if(state.period === periods.MONTH){
        type = sensorRegistersType.DAY_ACCUMULATED
      }
      else if(state.period === periods.YEAR){
        type = sensorRegistersType.MONTH_ACCUMULATED
      }
      else if(state.period === periods.TOTAL){
        type = sensorRegistersType.YEAR_ACCUMULATED
      }
    }

    if(isValidForAccumulated()){
      if(state.period === periods.WEEK){
        to.setDate(from.getDate() + 7)
        return {from, to, type}
      }
      else if(state.period === periods.MONTH){
        to.setMonth(from.getMonth() + 1)
        return {from, to, type}
      }
      else if(state.period === periods.YEAR){
        to.setMonth(from.getMonth() + 12)
        return {from, to, type}
      }
      else if(state.period === periods.TOTAL){
        return {from: new Date(0), to, type}
      }
    }

    return {from, to, type}
  
  }, [isValidForAccumulated, output, state.day, state.period])

  useEffect(() => {
    if (selectedTerminal?.id) {
      let future;
      const {from, to, type} = getRequestParams()
      if (output?.terminalDevice && output?.output > 0) {
        future = OutputService.getTerminalDeviceOutputRegistersByPeriod(
          selectedTerminal.id,
          output.terminalDevice,
          output?.output,
          getDayOfYearFormatted(from),
          getDayOfYearFormatted(to),
          type
        );
      } else if (
        sensor?.terminalDevice &&
        sensor?.sensorId?.id &&
        sensor?.sensorIndex !== undefined
      ) {
       
        future = sensorService.getTerminalDeviceSensorRegistersByPeriod(
          selectedTerminal.id,
          sensor.terminalDevice,
          sensor.sensorId.id,
          sensor.sensorIndex,
          getDayOfYearFormatted(from),
          getDayOfYearFormatted(to),
          type
        );
      }

      if (future) {
        future.then(
          (registers) => {
            if (registers instanceof Array) {
              // Inicializamos un objeto vacío donde vamos a almacenar los arrays por unidad.
              const measuresByUnit = {};
              // Iteramos sobre cada entrada del array data.
              registers
                .sort(
                  (aRegister, bRegister) =>
                    new Date(Date.parse(aRegister.receivedAt)) -
                    new Date(Date.parse(bRegister.receivedAt))
                )
                .forEach((reg) => {
                  const date = new Date(Date.parse(reg.receivedAt));

                  if (reg.measures?.length > 0) {
                    const measuresSource = (state.period === periods.DAY) ? reg.measures : reg.accumulated.accumulatedMeasures;
                    measuresSource?.forEach((measure) => {
                      // Si el array de la unidad aún no existe en el objeto measuresByUnit, lo creamos.
                      if (!measuresByUnit[measure.unit]) {
                        measuresByUnit[measure.unit] = {
                          units: [measure.unit],
                          measures: [],
                          accumulated: 0,
                          accumulatedMeasures: [],
                        };
                      }

                      measure.date = date;
                      measure.name = getHoursMinuteTimeFormatted(date);
                      measure[measure.unit] = measure.value;
                      if (
                        measure.dailyAverage !== undefined &&
                        measure.dailyAverage !== null &&
                        !isNaN(measure.dailyAverage)
                      ) {
                        const averageUnit = `(${measure.unit})/24h`;
                        measure[averageUnit] = measure.dailyAverage;

                        if (
                          measuresByUnit[measure.unit].units.includes(
                            averageUnit
                          ) === false
                        ) {
                          measuresByUnit[measure.unit].units.push(averageUnit);
                        }
                      }

                      // Añadimos la medida al array correspondiente a su unidad.
                      measuresByUnit[measure.unit].measures.push(measure);

                      let accumulatedMeasure = { ...measure };
                      const accumulatedNew =
                        measuresByUnit[measure.unit].accumulated +
                        measure[measure.unit];
                      accumulatedMeasure[measure.unit] = accumulatedNew;
                      measuresByUnit[measure.unit].accumulated = accumulatedNew;
                      measuresByUnit[measure.unit].accumulatedMeasures.push(
                        accumulatedMeasure
                      );
                    });
                  } else {
                    measuresByUnit["ud"] = measuresByUnit["ud"] || {
                      units: ["ud"],
                      measures: [],
                      accumulated: 0,
                      accumulatedMeasures: [],
                    };
                    measuresByUnit["ud"].measures.push({
                      value: reg.rawValue,
                      unit: "ud",
                      date,
                    });
                  }
                });

              setData(measuresByUnit);
            }
          },
          (error) => {
            setData([]);
          }
        );
      }
    }
  }, [sensor, state, selectedTerminal.id, output, getRequestParams]);

  const onPeriodChange = useCallback(({period, day}) => {
    setState(current => ({...current, period, day}))
    setData(undefined)
  }, [])

  const downloadTerminalDeviceSensorData = useCallback(
    (terminalDeviceSensorData) => {
      Object.keys(terminalDeviceSensorData).forEach((unit) => {
        const data = terminalDeviceSensorData[unit];
  
        // 1. Preparar los datos para el archivo Excel
        const worksheetData = [];
        
        // 2. Agregar los encabezados
        worksheetData.push(["Fecha", "Valor", "Media", "Unidad"]);
        
        // 3. Añadir los datos
        data.measures.forEach((item) => {
          worksheetData.push([
            `${item.date.toLocaleDateString()}-${item.date.toLocaleTimeString()}`,
            item.value,
            item.dailyAverage,
            unit
          ]);
        });
        
        // 4. Crear la hoja de cálculo (worksheet) y el libro de trabajo (workbook)
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "DatosSensor");
  
        // 5. Convertir el libro de trabajo a un archivo .xls
        const xlsData = XLSX.write(workbook, { bookType: "xls", type: "array" });
  
        // 6. Crear un blob con el contenido de Excel
        const blob = new Blob([xlsData], { type: "application/vnd.ms-excel" });
  
        // 7. Crear un enlace temporal para la descarga
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${selectedTerminal?.description}_${sensor?.description ?? ""}(S${sensor?.sensorIndex + 1})_${unit}_${state.period}_${getDateTimeFormatted(state.day)}.xls`;
  
        // 8. Simular el click para iniciar la descarga
        document.body.appendChild(link);
        link.click();
  
        // 9. Remover el enlace temporal
        document.body.removeChild(link);
      });
    },
    [state.day, selectedTerminal?.description, sensor?.description, sensor?.sensorIndex, state.period]
  );

  const getXData = useCallback((unit) => {
    const measuresSource = showAccumulated ? data[unit]?.accumulatedMeasures : data[unit]?.measures
    if (state.period === periods.WEEK) {
      return weekDays.map((day, index) => {
        // Buscar si hay un accumulatedMeasure correspondiente al día de la semana
        const measureForDay = measuresSource?.find((accumulatedMeasure) => {
          const dayOfWeek = new Date(accumulatedMeasure.date).getDay();
          return dayOfWeek === (index + 1) % 7; // Lunes es el primer día
        });
  
        // Si hay medida para el día, retornamos, si no, retornamos un accumulatedMeasure vacío
        return measureForDay
          ? { ...measureForDay, name: day }
          : {
              name: day,
              date: new Date(), // Usamos una fecha predeterminada si no hay valores
              unit, // Unidad predeterminada
              [unit]: 0,
              value: 0
            };
      });
    }
    else if (state.period === periods.MONTH){
      const year = state.day.getFullYear();
      const month = state.day.getMonth();
      const daysInMonth = getDaysInMonth(year, month); // Obtiene el número de días en el mes
  
      return Array.from({ length: daysInMonth }, (_, index) => {
        const day = new Date(year, month, index + 1); // Crea una fecha para cada día del mes
        const measureForDay = measuresSource?.find((accumulatedMeasure) => {
          const measureDay = new Date(accumulatedMeasure.date).getDate();
          return measureDay === day.getDate(); // Comparar día del mes
        });
  
        return measureForDay
          ? { ...measureForDay, name: day.getDate() }
          : {
              name: day.getDate(),
              date: day, // Asigna la fecha del día
              unit, // Unidad predeterminada
              [unit]: 0,
              value: 0
            };
      });
    }
    else if(state.period === periods.YEAR){
      return months.map((month, index) => {
        // Buscar si hay un accumulatedMeasure correspondiente al mes
        const measureForMonth = measuresSource?.find((accumulatedMeasure) => {
          const monthOfYear = new Date(accumulatedMeasure.date).getMonth();
          return monthOfYear === index;
        });

        // Si hay medida para el mes, retornamos, si no, retornamos un accumulatedMeasure vacío
        return measureForMonth
          ? { ...measureForMonth, name: month }
          : {
              name: month,
              date: new Date(), // Usamos una fecha predeterminada si no hay valores
              unit, // Unidad predeterminada
              [unit]: 0,
              value: 0
            };
      });
    }
    else if(state.period === periods.TOTAL){
      return measuresSource.map(measure => ({...measure, name: measure.date.getFullYear()}))
    }
  
    return measuresSource || [];
  }, [data, showAccumulated, state.day, state.period]);

  const getAccumulated = useCallback(unit => {
    const length = data[unit]?.accumulatedMeasures?.length || 0
    return length > 0 ? (data[unit].accumulatedMeasures[length - 1][unit] ?? 0) : 0
  }, [data])
  

  return (
    <>
      <div className="TitleMultipleStatics">
        <PeriodSelector onPeriodChange={onPeriodChange}/>
        { isValidForAccumulated() && (
          <div
            className="Button Secondary"
            style={{ width: "30%" }}
            onClick={(e) => setShowAccumulated((current) => !current)}
          >
            {showAccumulated ? "Intervalo" : "Acumulado"}
          </div>
        )}
        <div
          className="Button Secondary"
          onClick={(e) => downloadTerminalDeviceSensorData(data)}
        >
          <i className="fa fa-download fa-1" aria-hidden="true"></i>
        </div>

      </div>
      {!data && <div style={{textAlign: "center"}}><Spinner /></div>}
      {data && Object.keys(data).length === 0 &&  <>
        <DangerComponent message={"No hay datos disponibles"} />
        <BreakLine />
      </>}
      {data && Object.keys(data).map((unit, index) => {
        return (
          <>
            {/* Aquí se muestra el badge con el acumulado final */}
            {isValidForAccumulated() && (
              <div style={{textAlign: "center"}}>
                <div className="badge badge-info">
                  Acumulado: {getAccumulated(unit)?.toFixed(2)}{unit}
                </div>
              </div>
            )}
            <TerminalDeviceSensorLineGraph
              key={index}
              xData={getXData(unit)}
              yData={data[unit].units}
              yReferenceLines={
                sensor?.triggers instanceof Array &&
                sensor.triggers.filter((trigger) => !trigger?.deletedByUser)
              }
            />
            <BreakLine />
          </>
        );
      })}
    </>
  );
};
